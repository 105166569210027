.woocommerce nav.woocommerce-pagination {
	padding: 1rem 0;
	margin: 0 -0.5rem;

	ul.page-numbers {
		display: flex;
		flex-wrap: wrap;
		margin: 0;

		border: 0;

		li {
			display: block;
			margin: 0 0.5rem 0.5rem;

			border: 0;

			> * {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 2.5rem;
				width: 2.5rem;

				border: 1px solid $color-light-green;
				background-color: rgba($color-light-green, 0.2);
			}

			span {
				cursor: default;

				border: 1px solid rgba($color-light-green, 0.5);
			}
		}
	}
}


nav.pagination {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -0.5rem;
	padding: 1rem;

	> * {
		display: flex;
		padding: 0 0.5rem;
		margin: 0 0.5rem 0.5rem;
		align-items: center;
		justify-content: center;
		height: 2.5rem;
		min-width: 2.5rem;

		border: 1px solid $color-light-green;
		background-color: rgba($color-light-green, 0.2);

		&:not(span):hover {
			background-color: rgba($color-light-green, 0.3);
		}

		&.current,
		&.dots {
			cursor: default;

			color: rgba(#222, 0.5);
			border: 1px solid rgba($color-light-green, 0.3);
			background-color: rgba($color-light-green, 0.1);
		}
	}
}