body.page-template-staff-page {

	.staff-loop {
		article {
			width: 100%;
			display: inline-block;
			margin-bottom: 3rem;

			h2 {
				font-size: 2rem;
				margin-bottom: 1rem;
			}

			p {
				line-height: 1.8rem;
			}
		}
	}
}