@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);

// Foundation
@import "normalize";
@import "lib/foundation";

// Resets
@import "lib/reset";

// Constants and colors
@import "constants";
@import "colors";
@import "placeholders";

// Components, built-in, then custom
@import "core/*";
@import "components/*";

// Baseline styling
@import "base";

// Content areas
@import "sections/*";

// Content areas
@import "woo/*";

// Pages
@import "pages/*";