%text-input {
	padding: 0.5rem;

	outline: none;

	background-color: #FFF;
	border: 1px solid $color-light-green;
	
	transition: all 0.2s ease;

	&:focus {
		border-color: lighten($color-light-green, 10%);

		box-shadow: 0 0 0.625rem rgba($color-lightest-green, 1) inset;
	}
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="email"],
textarea {
	@extend %text-input;
}

%select-input {
	border: 1px solid $color-light-green;
}
select {
	@extend %select-input;
}

.gform_wrapper {
	max-width: 100%;
	margin: 0;
	outline: none;
	
	.validation_error {
		padding: 0.5rem;

		color: $color-error;
		text-align: center;

		border: 1px solid $color-error;
		background-color: rgba($color-error, 0.2);
	}

	.gform_body {
		position: relative;
		z-index: 10;

		.gform_fields {
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			.gfield {
				flex-grow: 1;
				display: block;
				margin: 0;
				padding: 1rem 0 0;

				label {
					display: block;

					color: $color-dark-green;
					font-weight: lighter;
					text-transform: uppercase;
				}

				.gfield_required {
					display: inline-block;
					margin: 0 0 0 0.25rem;

					color: $color-error;
				}

				input {
					width: 100%;
				}

				textarea {
					display: block;
					height: 100%;
					width: 100%;
				}

				&.gfield_error .validation_message {
					color: $color-error;
					font-size: 0.8em;
				}
			}
		}
	}

	.gform_footer {
		position: relative;
		z-index: 10;

		text-align: right;

		.gform_button {
			@extend %button;

			font-weight: 700;
			text-transform: uppercase;
		}
	}
}