$color-blue: #32588F;
$color-dark-green: #385E4A;
$color-light-green: #9CBC81;
$color-lime-green: #E9F1D6;
$color-lightest-green: #E7F2DC;
$color-medium-green: #6F9461;
$color-button: #a9c859;

$color-purple: #9C27B0;

$color-error: #f44336;