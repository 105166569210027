body.search-results {

	font-family: 'Lora', serif;

	ol.posts {
		counter-reset: ol;
		margin: 1em 1em 1em 0;

		> li {
			counter-increment: ol;
			margin-bottom: 15px;
			display: inline-block;
			padding-left: 30px;
			position: relative;


			h1 {
				font-size: 1.8rem;
				margin-bottom: 0px;
			}

			p {
				margin-top: 5px;
			}

			&::before {
				content: counter(ol)'.';
				margin-right: 0.5rem;
				position: absolute;
				left:0;
				font-size: 1.6rem;
				top: 0.1rem;
			}
		}
	}
}