body.woocommerce-cart .content-container {

	> h1 {
		display: none;
	}

	.shop_table {

		tbody .cart_item {

			.remove {
				display: flex;
				margin: 0 auto;
				align-items: center;
				justify-content: center;
				height: 3rem;
				width: 3rem;

				text-align: center;
				font-size: 3rem;
				line-height: 1em;

				color: $color-light-green;

				border-radius: 3px;
				border: 1px solid $color-lightest-green;

				&:hover {
					background-color: $color-lightest-green;
				}
			}

			.product-thumbnail {

				a {
					display: inline-block;
					padding: 0.25rem;

					border-radius: 3px;
					border: 1px solid $color-light-green;

					background-color: #FFF;

					img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}

		tbody .actions {
			padding: 0.5rem;

			.button {
				@extend %button;
			}

			.coupon {
				float: left;
				display: flex;
				align-items: center;

				label {
					display: none;
				}

				input[type="text"] {
					margin: 0 0.5rem;
				}
			}

			input[name="update_cart"] {
				float: right;
			}
		}
	}	

	.cart-collaterals {
		// border: 1px solid red;
		padding: 0.5rem 0 0;

		h2 {
			display: none;
		}

		.shop_table {
			border: 0;

			tbody tr {

				th {
					padding: 0.5rem;
					text-align: right;
				}

				td {
					padding: 0.5rem;
					width: 24rem;

					ul, ol {
						margin: 0;
					}
				}
			}

			ul {
				list-style: none;
			}
		}

		.wc-proceed-to-checkout {
			text-align: right;

			.button {
				@extend %button;
			}
		}
	}
}