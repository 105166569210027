body.page-template-page-contact {

	.store-hours {
		flex-grow: 1;

		float: right;
		flex-wrap: wrap;
		padding: 1rem;
		max-width: 15rem;
		
		background-color: $color-lime-green;
		border: 1px solid $color-light-green;

		color: $color-dark-green;

		.title {
			margin: 0 0 0.5rem;

			font-weight: 700;
			color: $color-dark-green;
		}
	}

	.title-hours {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.gform_wrapper .gform_body .gform_fields {

		.form-name,
		.form-name {
			flex-grow: 1;
			flex-basis: 12rem;
		}

		.form-name {
			padding-right: 2rem;
		}

		.form-message {
			flex-basis: 40rem;
		}
	}
}