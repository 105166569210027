body.woocommerce-account {

	.woocommerce {
		form.login {
			background-color: #fff;

			input[type="password"] {
				border: 1px solid $color-light-green;
			}

			input[type="submit"] {
				background-color: $color-lime-green;
				border: 1px solid $color-light-green;
				color: $color-dark-green;
				border-radius: 1px;
			} 
		}
	}
}