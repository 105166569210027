%woo-message {
	padding: 0.325rem;
	margin: 0 0 1rem;

	text-align: center;

	border-radius: 3px;
	border: 1px solid $color-light-green;

	background-color: rgba($color-light-green, 0.2);

	a.button, button {
		margin: 0 1rem;
	}
}
.woocommerce-message,
.woocommerce-error {
	@extend %woo-message;
}

.custom-store-message {
	@extend %woo-message;
	margin: 0 0 2rem;
	font-size: 1.4rem;
	font-weight: 300;
	color: $color-dark-green;
}

ul.woocommerce-error {
	margin-left: 0;
	margin-right: 0;

	border-color: $color-error;
	background-color: rgba($color-error, 0.1);
}

.shop_table {
	// margin: 0 0 1rem;
	width: 100%;

	border: 1px solid $color-light-green;

	thead th {
		padding: 0.5rem;

		font-weight: bold;
		background-color: rgba($color-light-green, 0.2);
		border-bottom: 1px solid rgba($color-light-green, 0.3);

		&.product-name {
			text-align: left;
		}

		&.product-subtotal,
		&.product-total {
			text-align: left;
		}
	}

	tbody .cart_item {
		// border: 1px solid red;

		td {
			padding: 0.5rem;

			text-align: center;
			vertical-align: middle;

			border-bottom: 1px solid rgba($color-light-green, 0.2);

			&:not(:last-child) {
				border-right: 1px solid rgba($color-light-green, 0.1);
			}

			&.product-name {
				text-align: left;
				font-size: 1.2rem;

				a {
					display: block;
				}

				.variation {
					display: flex;
					padding: 0.25rem 0.5rem;

					font-size: 1rem;
					line-height: 1rem;
					color: #999;

					&:not(:last-of-type) {
						margin: 0 0 0.5rem;
					}

					> dt {
						margin: 0 0.5rem 0 0;
					}

					> dd {
						color: #666;
					}

					p {
						margin: 0;
						line-height: 1rem;
					}
				}
			}

			&.product-subtotal,
			&.product-total {
				text-align: left;
				font-size: 1.2rem;
			}
		}
	}

	tfoot tr {

		th {
			padding: 0.5rem;
			text-align: right;
		}

		td {
			padding: 0.5rem;

			ul, ol {
				margin: 0;
			}
		}
	}

	.cart-subtotal,
	.order-total {
		font-size: 1.2rem;
	}
}



.product-info-tabs {
	margin: 0 0 2rem;
	padding: 0 0 2rem;

	border-bottom: 1px solid $color-lightest-green;

	nav {
		display: flex;
		flex-wrap: wrap;

		border-bottom: 2px solid $color-light-green;

		[data-product-tab] {
			display: block;
			padding: 1rem 2rem;

			background-color: rgba(#E9E9E9, 0.5);

			&[data-status="active"] {
				color: white;
				background-color: $color-light-green;
			}

			&:not(:last-of-type) {
				margin: 0 3px 0 0;
			}
		}
	}

	.tabs {

		[data-product-tab] {
			display: none;
			padding: 1rem;

			background-color: rgba($color-light-green, 0.2);

			&[data-status="active"] {
				display: block;
			}

			p {
				margin: 0;

				&:not(:last-of-type) {
					margin: 0 0 0.5rem;
				}
			}
		}
	}
}