body.woocommerce.single-product {

	.content-container.single-product-container {
		flex-wrap: nowrap;
		flex-direction: row;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.category-sidebar {
			@extend %sidebar;
		}
	}

	.product {
		@extend %primary;

		.product-header {
			display: flex;
			padding: 0 0 2rem;
			margin: 0 0 2rem;
			border-bottom: 1px solid $color-lightest-green;

			@media (max-width: 840px) {
				flex-direction: column;
			}
		}

		.images {
			flex-basis: 20rem;
			flex-shrink: 0;

			margin: 0 2rem 0 0;

			@media (max-width: 840px) {
				flex-basis: auto;
				margin: 0;
			}

			.woocommerce-main-image {
				position: relative;

				display: flex;
				align-items: flex-start;
				justify-content: center;
				margin: 0 0 1rem;

				img {
					display: block;
				}

				.icon-wrapper {
					position: absolute;
					z-index: 10;
					top: 0;
					left: 0;

					display: flex;
					flex-direction: column;
				}

				.icon {
					display: block;
					height: 3.5rem;
					width: 3.5rem;

					background-size: contain;
					background-position: top left;
					background-repeat: no-repeat;
				}
			}

			.thumbnails {
				display: flex;
				justify-content: center;

				a {
					flex-grow: 1;
					flex-basis: 6rem;

					display: flex;
					align-items: center;
					justify-content: center;
					max-height: 6rem;

					img {
						display: block;
						max-height: 100%;
						width: auto;
					}
				}
			}
		}

		.product-summary {
			flex-grow: 1;

			.product_title {
				font-size: 1.6rem;
			}

			p {
				margin: 0 0 1rem;
			}
			
			[itemprop="description"] {
				color: #999;
				font-size: 0.9em;
			}

			.price {
				color: $color-light-green;
				font-size: 1.2rem;
			}

			.variations {
				margin: 0 0 0.5rem;

				td.label {
					padding: 0 1rem 0 0;
					font-size: 1.1rem;
				}

				select {
					margin: 0 0 0.25rem;
				}

				.reset_variations {
					color: $color-light-green;
					font-size: 0.9rem;
				}
			}

			.single_variation {
				padding: 0.5rem 0;
				margin: 0 1rem 0 0;

				.price {
					color: $color-dark-green;
					font-size: 1.4rem;
				}
			}

			.woocommerce-variation-add-to-cart {
				display: flex;

				.quantity {
					display: flex;
				}

				input[type="number"] {
					margin: 0 1rem 0 0;
					padding: 0.25rem;
					width: 100px;

					font-size: 1.4rem;
					text-align: center;
				}

				button[type="submit"] {
					@extend %button;
					margin: 0;

					&.disabled {
						background-color: #DDD;
						color: #999;
					}
				}
			}

			.cart:not(.variations_form) {
				display: flex;

				.quantity {
					display: flex;
				}

				input[type="number"] {
					margin: 0 1rem 0 0;
					padding: 0.25rem;
					width: 100px;

					font-size: 1.4rem;
					text-align: center;
				}

				button[type="submit"] {
					@extend %button;
					margin: 0;

					&.disabled {
						background-color: #DDD;
						color: #999;
					}
				}
			}
		}
	}

	.related.products {

		h2 {
			font-size: 1.4rem;
		}
	}
}