.page-content.account-container {
	display: inline-block;
	width: 100%;

	.woocommerce-Message {
		border-top-color: $color-dark-green ;

		&::before {
			color:  $color-dark-green ;
		}
	}

	nav.woocommerce-MyAccount-navigation {
		ul {

			margin: 0;
			margin-bottom: 2rem;
			border-right: 3px solid $color-dark-green;

			li {
				list-style: none;
				padding: 0.5rem;
				background-color: #f7f6f7;
				border-bottom: 3px solid #fff;
				margin: 0;

				&:last-child {
					border-bottom: 0;
				}

				&:hover {
					background-color: darken(#f7f6f7, 5%);
				}

				&.is-active {

					background-color: darken(#f7f6f7, 5%);

					a::after {
						content: '\f105';
						font-family: fontawesome;
						color: $color-dark-green;
						margin-left: 0.5rem;
					}
				}

				a {
					width: 100%;
					display: inline-block;

					&:hover {
						color: $color-dark-green;
					}
				}
			}
		}
	}

	.woocommerce-MyAccount-content {
		
		a.edit {
			margin-bottom: 1rem;
			display: inline-block;
		}

		input.button {
			background-color: $color-light-green;
			color: #fff;
			font-weight: lighter; 
		}
	}

	form.login {
		max-width: 22rem;

		input[name="login"] {
			margin-bottom: 1rem;
			display: inline-block;
		}
	}
}