main {
	margin: 0 0 1rem;
	padding: 0.5rem 0 1rem;

	background: url('../images/bg-image-white.png');
	background-size: cover;
	background-position: center;

	@media (max-width: $row-width) {
		padding: 0.5rem 1rem 1rem;
	}

	@media (max-width: 40rem) {
		padding: 0.5rem;
	}

	> .content {
		margin: 0 auto;
		max-width: $row-width;
		width: 100%;
		padding: 3rem;

		background-color: rgba(#FFF, 0.7);
		border: 2px solid $color-lime-green;
		box-shadow: 2px 3px 10px rgba($color-dark-green, 0.1);

		@media (max-width: 768px) {
			padding: 1.5rem;
		}

		.content-container {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			&.columns-container {
				flex-direction: row;
			}

			> .left {
				flex-grow: 1;
				flex-basis: 26rem;

				margin-right: 2rem;

				@media (max-width: 1024px) {
					order: 2;
				}
			}

			> .right {
				flex-grow: 1;
				flex-basis: 30rem;

				order: 2;

				@media (max-width: 1024px) {
					order: 1;
				}
			}
		}
	}
}