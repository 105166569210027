// This file contains core components for Skeleton.

/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
@mixin grid-box($count, $spacing:10px, $vspacing:$spacing) {
	vertical-align: top;
	display: inline-block;

	width: calc(#{100% / $count} - #{$spacing});
	margin-right: $spacing;
	margin-bottom: $vspacing;

	&:nth-of-type(#{$count}n + #{$count}) {
		margin-right: 0;
	}
}

/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
@function break-bigger($width) {
	@return "screen and (min-width: #{$width})";
}

@function break-smaller($width) {
	@return "screen and (max-width: #{$width})";
}

// Transition between desktop and mobile nav
$break-nav-width: 40rem;
$break-nav-bigger: break-bigger($break-nav-width);
$break-nav-smaller: break-smaller($break-nav-width);

// Is the screen wider or narrower than the page?
$break-page-width: 71.25rem;
$break-page-bigger: break-bigger($break-page-width);
$break-page-smaller: break-smaller($break-page-width);

// Add variables like these specific to the site in _constants.scss instead of here.

/* Touch Nav Detection (1.1.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
@mixin with-touch-nav($use-parent: true) {
	@media #{$break-nav-smaller} {
		@content;
	}
}

@mixin without-touch-nav {
	@media #{$break-nav-bigger} {
		@content;
	}

	@if ($use-parent) {
		@at-root html.no-touch & {
			@content;
		}
	} @else {
		@at-root html.no-touch {
			@content;
		}
	}
}