body.woocommerce-account {

	.woocommerce-MyAccount-navigation {
		border-bottom: 2px solid $color-medium-green;

		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;

			list-style: none;

			li {
				margin: 0;
				padding: 0;

				a {
					display: block;
					padding: 0.5rem 1.5rem;

					background-color: rgba($color-medium-green, 0.2);

					&:hover {
						background-color: rgba($color-medium-green, 0.3);
					}
				}

				&.is-active a {
					color: #FFF;
					background-color: rgba($color-medium-green, 1);
				}
			}
		}
	}
}