body.post-type-archive-videos {
	
	main.archive {
		
		div.wysiwyg {

			
			div.videos-container {

				display: flex;
				flex-wrap: wrap;
				max-width: 100%;
				padding: 3rem 6rem;
				margin-bottom: 7rem;
				justify-content: space-between;
				border: 2px solid #E9F1D6;
				background-color: #fff;
				box-shadow: 1px 1px 15px 0px rgba(50, 50, 50, 0.5);


				@media (max-width: 640px) {

					padding: 2.5rem 1rem 2.5rem 1rem;
					margin-bottom: 0;
					border: none;
					box-shadow: none;
				}

				h2 {

					text-align: center;
					width: 100%;
				}

				div.posts {
					
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;


					article.wysiwyg {

						max-width: 100%;
						min-width: 17.5rem;
						padding: 1rem;
						flex-grow: 1;
						text-align: center;
						flex-basis: 20rem;


						div.main-image {
							
							height: 15rem;
							width: 100%;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: center;
							text-align: center;
							display: flex;
							align-items: center;
							position: relative;

							&::after {

								content: '\f01d';
								font-family: fontawesome;
								font-size: 4rem;
								color: white;
								width: 100%;
								height: 100%;
								position: absolute;
								top: 35%;
								left: 0;
								z-index: 10;
								
								

							}
						}

						h5 {

							text-transform: uppercase;
							padding-top: 0.7rem;
							font-weight: lighter;
						}

						div.content {

							
							p {

								max-width: 100%;
								margin-top: 0.5rem;
							}

							a {

								
							}
						}

					}
					
					.dummy {

						max-width: 100%;
						min-width: 17.5rem;
						padding: 1rem;
						height: 0;
						margin-top: 0;
						margin-bottom: 0;
						padding: 0;
						visibility: hidden;
						flex-grow: 1;
						flex-basis: 20rem;
					}
				}
			}
		}
	}
}	