// Add this class or pseudoclass to any content generated by WordPress.
// Additionally, it can be used when you want to ensure consistent styling.

// Edit + remove styles here freely.

%wysiwyg, .wysiwyg {
	font-family: 'Lora', serif;

	&::after {
		clear: both;
		display: block;

		content: "";
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0 0 0.5rem;
		color: $color-dark-green;
	}

	h1 { font-size: 2.6rem; }

	h2 { font-size: 2rem; }

	h3 { font-size: 1.6rem; }

	h4 { font-size: 1.4rem; }

	h5, h6 {
		font-size: 1rem;
		margin-bottom: 5px;
	}

	p {
		margin: 1rem 0;
		line-height: 1.5rem;
	}

	hr {
		margin: 20px 0;
	}

	ul {
		list-style: disc;
		margin: 0 0 1rem 1.5rem;

		li {
			margin-bottom: 1rem;
			line-height: 1.5rem;
		}
	}

	ol {
		counter-reset: ol;
		margin: 0 0 1rem 0;

		> li {
			counter-increment: ol;
			margin-bottom: 1rem;
			line-height: 1.5rem;

			&::before {
				content: counter(ol);
				margin-right: 0.5rem;

				font-size: 2em;
				color: $color-light-green;
			}
		}
	}

	a {
		color: $color-purple;
		text-decoration: none;

		&:hover {
			color: darken($color-purple, 8%);
		}
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	button, a.button, input[type="button"] {
		@extend %button;
	}

	blockquote {
		cite {
		}
	}
}