.css-events-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 100%;

	@media (max-width: 640px) {
		padding: 2.5rem 1rem;
		margin-bottom: 0;
	}

	h2 {
		text-align: center;
		width: 100%;
	}

	.event {
		flex-grow: 1;
		flex-basis: 20rem;

		max-width: 100%;
		min-width: 17.5rem;
		padding: 1rem;

		text-align: center;

		.main-image {
			height: 15rem;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			text-align: center;
			display: flex;
			align-items: center;
			position: relative;

			&::after {
				position: absolute;
				z-index: 10;
				left: 0;
				top: 0;

				width: 100%;
				height: 100%;

				opacity: 0;
				background-color: rgba($color-lime-green, 0.8);

				content: '';

				transition: all 0.7s ease;
			}

			.date {
				position: relative;
				z-index: 20;

				padding: 1rem 0.5rem;
				margin: 0 auto;
				width: 80%;

				color: white;
				text-transform: uppercase;

				background-color: $color-dark-green;
				opacity: 0;
				transition: all 0.7s ease;
			}

			&:hover {

				&::after,
				.date {
					opacity: 1;
				}
			}
		}

		h5 {
			text-transform: uppercase;
			padding-top: 0.7rem;
			font-weight: lighter;
		}

		div.content {

			p {
				max-width: 100%;
				margin-top: 0.5rem;
			}
		}
	}
	
	.dummy {
		max-width: 100%;
		min-width: 17.5rem;
		padding: 1rem;
		height: 0;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		visibility: hidden;
		flex-grow: 1;
		flex-basis: 20rem;
	}
}

.event-details {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	.event-left {
		flex-grow: 1;
		flex-basis: 28rem;

		display: inline-block;
		margin-right: 2rem;
		max-width: 27rem;
		max-height: 25rem;
		height: 20rem;

		order: 1;

		background-size: cover;
		background-position: center;
		
		@media (max-width: 995px) {
			order: 2;
		}
	}

	.event-right {
		flex-basis: 2rem;
		flex-grow: 1;

		order: 2;

		@media (max-width: 995px) {
			order: 1;
		}

		h2 {
			text-align: left;
			width: 100%;
		}
		
		.time-cost-container {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.date {
				position: relative;
				z-index: 10;

				margin: 0 2rem 0 0;
				padding: 1rem 0.5rem;
				width: 19rem;

				background-color: $color-dark-green;

				text-align: center;
				color: white;
				text-transform: uppercase;
			}

			.event-cost {
				flex-grow: 1;
			}
		}
		a.location-map {

			p.title-address {
				margin-bottom: 0.5rem;

				&::before {
					content: '\f041';
					font-family: fontawesome;
					padding-right: 0.5rem;
				}
			} 

			p.title-state-zip {
				margin-top: 0.5rem;
				padding-left: 1rem;
				padding-bottom: 16px;

				border-bottom: 1px solid $color-lime-green;
			}
		}

		a.em-booking-button {

			@extend %button;
			padding: 0.6rem 3rem;

		}
	}
}