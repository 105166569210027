main.page.archive-recipe > .content {
	padding: 1rem;
}


main.page.archive-recipe .recipes {
	display: flex;
	flex-wrap: wrap;

	.recipe {
		flex-grow: 1;
		flex-basis: 20rem;

		position: relative;

		display: flex;
		margin: 1rem;

		border: 1px solid $color-light-green;

		overflow: hidden;

		&.dummy {
			display: block;
			margin-top: 0;
			margin-bottom: 0;
			height: 0;
			border: 0;
			visibility: hidden;
		}

		.image {
			position: relative;
			z-index: 10;

			display: block;
			width: 100%;
			min-height: 16rem;

			transition: all 0.5s ease;
			backface-visibility: hidden;
			box-shadow: 0 0 0 10rem rgba($color-dark-green, 0) inset;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		&:hover .image {
			// box-shadow: 0 0 0 2rem rgba($color-dark-green, 0.3) inset;
			transform: scale(1.0625);
		}

		.title {
			position: absolute;
			z-index: 20;
			bottom: 0;
			left: 0;

			padding: 3rem 2rem 1rem;
			width: 100%;

			text-align: right;
			color: #FFF;
			font-size: 1.4rem;
			text-shadow: 0 2px 3px rgba(#000, 0.4);

			backface-visibility: hidden;
			// background-color: rgba(#000, 0.3);
			background-image: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, 0.2) 30%	, rgba(#000, 0.8));
		}
	}
}


main.page.single-recipe {

	.featured {
		width: 100%;
	}
}