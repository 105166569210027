// This is the main /shop page template, list of categories
.product-categories {
	display: flex;
	flex-wrap: wrap;

	margin: 0 -1rem;

	.category {
		flex-grow: 1;
		flex-basis: 18rem;

		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 1rem;
		padding: 1rem 0 0;

		background-color: #FFF;
		border: 1px solid $color-lightest-green;

		&.dummy {
			height: 0;
			padding: 0;
			margin-top: 0;
			margin-bottom: 0;

			visibility: hidden;
		}

		.category-image {
			display: block;
			width: 100%;
			height: 10rem;

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.category-title {
			text-align: center;
			font-size: 1.4rem;
		}

		> ul {
			display: block;
			margin: 0;
			padding: 0 2rem;
			width: 100%;

			list-style: none;

			> li {
				margin: 0 0 0.5rem;

				.button {
					width: 100%;
					@extend %button;

					text-align: center;
				}
			}
		}
	}
}


.shop-primary .term-description {
	margin: 0 0 2rem;
	font-size: 1.2em;
}


// Product archive
main.woocommerce-page .content-container.category-container {
	flex-direction: row;
	flex-wrap: nowrap;

	@media (max-width: 1024px) {
		flex-direction: column;
	}

	.shop-sidebar {
		@extend %sidebar;
	}

	.shop-primary {
		@extend %primary;

		.archive-meta {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.woocommerce-message {
				@extend %woo-message;
				flex-basis: 100%;
				min-width: 100%;
			}

			.woocommerce-result-count {
				margin: 0;
			}
		}

		
	}
}

main.woocommerce-page {

	ul.products {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -0.5rem;

		.product {
			flex-grow: 1;
			flex-basis: 14rem;

			display: flex;
			flex-direction: column;
			margin: 0.5rem;
			padding: 1rem;

			border-radius: 4px;
			border: 1px solid lighten($color-light-green, 20%);
			background-color: #FFF;

			transition: border 0.3s ease;

			&:hover {
				border-color: $color-light-green;
			}

			&.dummy {
				margin-top: 0;
				margin-bottom: 0;
				padding: 0;

				height: 0;
				border: 0;
				visibility: hidden;
			}

			.thumbnail {
				display: block;
				height: 12rem;
				width: 100%;

				border-radius: 3px;
				overflow: hidden;

				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;

				.icon {
					height: 3rem;
					width: 3rem;

					background-position: top left;
					background-size: contain;
					background-repeat: no-repeat;

					&:not(:last-of-type) {
						// margin-bottom: 0.5rem;
					}
				}
			}

			.product-title {
				display: block;
				padding: 0.25rem 0;

				font-size: 1.2em;
				text-align: center;
			}

			.price {
				flex-grow: 1;

				display: block;
				margin: 0 0 0.5rem;

				text-align: center;
			}

			.button {
				display: block;
				margin: 0;
				padding: 0.5rem;

				text-align: center;

				border: 0;
				background-color: rgba($color-light-green, 0.2);

				&:hover {
					background-color: rgba($color-light-green, 0.4);
				}
			}

			.added_to_cart {
				display: block;
				margin: 0.5rem 0 0;
				padding: 0.5rem;

				text-align: center;

				border: 0;
				background-color: rgba($color-light-green, 0.2);

				&:hover {
					background-color: rgba($color-light-green, 0.4);
				}
			}
		}
	}
}