%custom-checkbox {

	input[type="checkbox"] {
		display: none;
	}

	label {
		display: flex;

		&::before {
			display: block;
			margin: 0;
			width: 1.5rem;

			content: "\f096";
			color: $color-light-green;
			font-family: fontawesome;
		}
	}

	input[type="checkbox"]:checked + label::before {
		content: "\f046";
	}
}

#shipping_method {
	list-style: none;
}

body.woocommerce-checkout .content-container {

	> h1 {
		display: none;
	}

	h3 {
		font-size: 1.3rem;
	}

	.woocommerce > .woocommerce-info {
		margin: 0 0 1rem;

		text-align: center;
	}

	.woocommerce > .login {
		margin: 0 0 1rem;
		padding: 1rem;
		width: 100%;

		background-color: #FFF;
		border-radius: 3px;
		border: 1px solid $color-lightest-green;

		p {
			margin: 0 0 0.5rem;

			&:last-of-type {
				margin: 0;
			}
		}

		.button {
			@extend %button;
		}
	}

	.woocommerce > .checkout_coupon {
		display: flex;
		padding: 1rem;

		border: 1px solid $color-light-green;

		.form-row {
			display: flex;
			margin: 0;
			float: none;
		}

		.clear {
			display: none;
		}

		.button {
			@extend %button;
			margin: 0;
		}
	}

	$col-break: 860px;
	.col2-set {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 2rem;

		@media (max-width: $col-break) {
			flex-direction: column;
		}

		> div {
			flex-grow: 1;
			flex-basis: 1rem;

			@media (max-width: $col-break) {
				flex-basis: auto;
			}
		}

		.col-1 {
			padding-right: 1rem;

			@media (max-width: $col-break) {
				margin-bottom: 1rem;
				padding-right: 0;
			}
		}

		.col-2 {
			padding-left: 1rem;

			@media (max-width: $col-break) {
				padding-left: 0;
			}
		}
	}

	.form-row {
		margin: 0 0 0.5rem;

		&.form-row-first,
		&.form-row-last {
			float: left;
			width: 50%;

			@media (max-width: 540px) {
				float: none;
				width: 100%;
			}
		}

		&.form-row-first {
			padding-right: 0.5rem;

			@media (max-width: 540px) {
				padding-right: 0;
			}
		}

		&.form-row-last {
			padding-left: 0.5rem;

			@media (max-width: 540px) {
				padding-left: 0;
			}
		}

		label {
			display: block;

			color: #666;
		}

		textarea,
		input[type="text"],
		input[type="tel"],
		input[type="password"],
		input[type="email"] {
			display: block;
			width: 100%;
		}

		&.woocommerce-invalid {
			textarea,
			input[type="text"],
			input[type="tel"],
			input[type="password"],
			input[type="email"] {
				border-color: $color-error;
				background-color: rgba($color-error, 0.1);
			}
		}
	}

	.select2-container .select2-choice {
		border-color: $color-light-green;
	}

	.select2-container .select2-choice > .select2-chosen {
		padding: 0.5rem;
	}

	.form-row.create-account {
		@extend %custom-checkbox;
	}


	.woocommerce-checkout-payment#payment {

		.payment_methods {
			margin: 0 0 1rem;
			padding: 1rem 0 0;

			border: 0;

			> li {
				display: block;
				margin: 0;
				padding: 0;

				&:not(:last-of-type) {
					margin-bottom: 1rem;
				}

				> input {
					display: none;
				}

				> label {
					display: flex;
					align-items: center;
					padding: 1rem;

					cursor: pointer;
					font-size: 1.2rem;

					transition: all 0.3s ease;

					background-color: #FFF;
					border: 1px solid $color-light-green;

					&::before {
						display: block;
						width: 1.5rem;
						transition: all 0.2s ease;

						content: "\f10c";
						font-family: fontawesome;
						color: $color-dark-green;
					}

					&:hover {
						background-color: rgba($color-light-green, 0.05);
					}

					img {
						display: inline-block;
						margin: 0 0.5rem;
					}
				}

				input:checked + label {
					background-color: rgba($color-light-green, 0.1);

					&::before {
						content: "\f192";
						color: $color-light-green;
					}
				}

				div.payment_box {
					margin: 0;
					padding: 0.5rem 1rem;

					border: 1px solid $color-light-green;
					border-top: 0;

					background-color: rgba($color-light-green, 0.3);

					p {
						margin: 0;
					}
				}
			}
		}

		.place-order {

			.button {
				@extend %button;
				display: block;
				padding: 1rem 3rem;
				margin: 0 auto;
				font-size: 1.4rem;
			}
		}
	}
}




#ship-to-different-address {
	@extend %custom-checkbox;
}