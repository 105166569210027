body.page-template-page-about {

	.featured-image {
		height: 100%;
		min-height: 20rem;
		width: 100%;

		background-position: right center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	p {
		margin-top: 0;
		padding-bottom: 1rem;
		border-bottom: 1px solid $color-button;
	}

	.button-content {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1rem;

		a {
			flex-grow: 1;
			flex-basis: 8rem;

			display: flex;
			align-items: center;
			justify-content: center;
			margin: 1rem;

			text-align: center;
			text-transform: uppercase;
		}
	}
}