/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

@import url(https://fonts.googleapis.com/css?family=Raleway:600,300,700|Lora:400,700);

html, body {
	// overflow-x: hidden;
}

body {
	font-family: sans-serif;
	line-height: 1.3;
	background-color: $color-dark-green;
}

a {
	color: $color-dark-green;
	text-decoration: none;

	transition: all 0.2s ease-in-out;
}

.alignright {
	float: right;
	margin: 1rem 0 1rem 1rem;
}

.alignleft {
	float: left;
	margin: 1rem 1rem 1rem 0;
}

.icon-item {
	position: relative;

	padding-left: 2em;

	&:not(:last-of-type) {
		margin: 0 0 0.5rem;
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		height: 1.5rem;
		width: 1.5rem;

		content: "";
		text-align: center;
		font-size: 1.1rem;
		font-family: fontawesome;
		color: $color-dark-green;
	}

	&.address::before { content: '\f041'; }
	&.email::before { content: '\f0e0'; }
	&.phone-number::before { content: '\f095'; }
}

.clear {
	clear: both;
	display: block;
	height: 0;
	width: 100%;
}

%fancy-list-item {
	display: flex;

	&::before {
		display: block;
		height: 1.2rem;
		width: 1rem;

		line-height: 1em;
		content: "\00bb";
		color: $color-light-green;
	}
}

li.fancy {
	@extend %fancy-list-item;
}