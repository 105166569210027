%button {
	display: inline-block;
	margin: 0.5rem 0;
	padding: 0.625rem 1.25rem;

	color: $color-dark-green;
	font-family: 'Lora', serif;

	border: 1px solid $color-light-green;
	background-color: $color-lightest-green;

	white-space: normal;
	cursor: pointer;

	transition: all 0.2s ease;

	&:hover {
		color: #FFF;

		border-color: $color-dark-green;
		background-color: $color-dark-green;
	}
}
input[type="submit"] {
	@extend %button;
}



%sidebar {
	flex-basis: 16rem;
	flex-shrink: 0;

	margin-right: 2rem;

	@media (max-width: 1024px) {
		margin-right: 0;
		order: 2;
	}

	header {

		.sidebar-image {
			display: block;
			width: 100%;
			height: 10rem;

			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		.sidebar-title {
			text-align: center;
			font-size: 1.4rem;
		}
	}

	ul {
		margin: 0 0 2rem;

		> li {
			display: block;
			margin: 0;

			a {
				display: block;
				padding: 0.325rem 0.5rem;

				background-color: $color-lightest-green;

				&:hover {
					padding-left: 0.625rem;

					color: #222;

					background-color: darken($color-lightest-green, 5%);
				}
			}
		}
	}

	.icon-legend {
		margin: 0 0 1rem;

		.item {
			display: flex;

			&:not(:last-of-type) {
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;
			}

			.icon {
				height: 3rem;
				width: 3rem;

				background-size: contain;
				background-position: top left;
				background-repeat: no-repeat;
			}

			.name {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				.desc {
					font-size: 0.8em;
					color: #666;
				}
			}
		}
	}
}



%primary {
	flex-grow: 1;
	flex-basis: auto;
}



