#fixed-header-placeholder {
	// transition: height 0.2s ease-in-out;
	height: 196px;

	@media (max-width: 40rem) {
		height: 60px;
	}
}

body > header.desktop {
	position: fixed;
	z-index: 40;
	left: 0;
	top: 0;

	display: block;
	width: 100%;

	@include with-touch-nav {
		display: none;
	}

	.top-bar {
		width: 100%;

		color: #fff;
		font-family: 'Raleway', sans-serif;

		background-color: $color-dark-green;

		.grid-row {
			display: flex;
			justify-content: flex-end;
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: $row-width;
		}

		nav {
			display: flex;

			&:not(:first-child) {
				margin-left: 1rem;
			}

			a {
				display: flex;
				align-items: center;
				padding: 0.25rem 0.5rem;

				color: #FFF;

				&:hover {
					background-color: lighten($color-dark-green, 10%);
				}
			}

			a.my-account {
				text-transform: uppercase;
				font-size: 0.8em;
			}

			a.cart-link {

				 &::before {
				 	display: inline-block;
				 	margin: 0 0.25rem 0 0;

				 	content: "\f07a";
				 	font-family: fontawesome;
					-webkit-font-smoothing: antialiased;
				 }
			}
		}

		.social a {
			display: block;
			padding: 0;
			margin: 0;
			
			&::before {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.25rem 1rem;

				text-align: center;
				font-size: 1.2rem;
				font-family: fontawesome;
				-webkit-font-smoothing: antialiased;
			}
		}
	}

	.site-branding {
		background-color: $color-light-green;

		@media (max-width: $row-width) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.grid-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			width: 100%;
			max-width: $row-width;
			padding: 0.5rem 0;
		}

		a.logo {
			display: block;
			height: 6rem;
			width: 30rem;
			
			background-image: url('../images/logo.svg');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;

			img {
				width: 100%;
				max-width: 400px;
				height: auto;
				padding-right: 1rem;
				display: block;
			}				
		}

		.search-form {
			display: flex;
			margin-left: 2rem;

			input {
				display: block;
				padding: 0.5rem;
				width: 18rem;

				overflow: hidden;

				font-weight: normal;

				outline: none;
				border: 0;
				border-radius: 0.25rem 0 0 0.25rem;
				box-shadow: inset 0 0 0.5rem 0.25rem #E9F1D6;
				background-color: #FFF;

				&::-webkit-input-placeholder {
					font-size: 0.9em;
					font-style: italic;
					color: rgba(#222, 0.5);
				}
			}

			button {
				display: flex;

				outline: none;
				border-radius: 0 0.25rem 0.25rem 0;
				background-color: $color-medium-green;

				&::before {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0.25rem 1rem;

					content: "\f002";
					color: #FFF;
					text-align: center;
					font-size: 1.2rem;
					font-family: fontawesome;
					-webkit-font-smoothing: antialiased;
				}
			}
		}
	}

	nav.primary {
		position: relative;
		z-index: 120;

		display: flex;
		justify-content: space-around;

		font-family: 'Lora', serif;
		color: $color-dark-green;

		background-color: rgba(#FFF, 1);
		border-bottom: 1px solid $color-light-green;

		> div {
			margin: 0 auto;
			max-width: $row-width;
			width: 100%;
		}

		ul {
			position: relative;

			display: flex;

			> li {
				// position: relative;
				flex-grow: 1;

				display: block;

				> a {
					position: relative;

					display: block;
					padding: 0.75rem 2rem;

					color: #306b1c;
					text-align: center;
					font-size: 1.3rem;

					@media (max-width: $row-width) {
						padding: 0.75rem 1rem;

						font-size: 1.1rem;
					}

					@media (max-width: 768px) {
						font-size: 0.9rem;
					}

					&::after {
						position: absolute;
						z-index: 120;
						left: 50%;
						top: 100%;
						transform: translateX(-50%);

						display: block;
						height: 0;
						width: 39px;

						content: "";
						transition: height 0.2s ease;

						background-image: url('../images/triangle.png');
						background-position: top center;
						background-repeat: no-repeat;
						background-size: contain;
					}
				}

				$color-sub-border: rgba($color-light-green, 0.5);
				> .sub-menu {
					position: absolute;
					z-index: 140;
					top: calc(100% + 0.5rem);
					left: 0;

					display: flex;
					flex-wrap: wrap;
					max-width: $row-width;
					width: 100%;

					transition: transform 0.3s ease, opacity 0.3s ease;

					transform: scale(0.9);
					transform-origin: top center;
					opacity: 0;

					visibility: hidden;

					border-top: 1px solid $color-sub-border;
					border-left: 1px solid $color-sub-border;

					box-shadow: 0 0.5rem 2rem rgba(#000, 0.35);

					@media (max-width: $row-width) {
						left: 1rem;

						max-width: calc(#{$row-width} - 2rem);
						width: calc(100% - 2rem);
					}

					&::before {
						position: absolute;
						bottom: 100%;
						left: 0;

						display: block;
						height: 0.625rem;
						width: 100%;

						content: "";
					}

					li {
						display: flex;
						flex-grow: 1;
						flex-basis: 18rem;
						text-align: center;

						background-color: #FFF;

						transition: all 0.2s ease;

						box-shadow: 0 0 0 0 rgba(#FFF, 0.5) inset;

						border-right: 1px solid $color-sub-border;
						border-bottom: 1px solid $color-sub-border;

						&:hover {
							box-shadow: 0 0 0 0.625rem rgba($color-light-green, 0.2) inset;
						}

						a {	
							display: block;
							width: 100%;

							&:hover {
								color: $color-medium-green;
							}

							&::after {
								display: none;
							}

							img {
								display: block;
								margin: 0 auto 0.5rem;
								padding: 0;
								width: auto;
								height: auto;
								max-height: 6rem;
							}

							.menu-image-title {
								display: block;

								font-size: 1.0rem;
								line-height: 1.2rem;
								text-transform: uppercase;
							}
						}
						
						&:hover {

							&::after {
								display: none;
							}
						}

						&.all-products {
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 1rem 2rem;

							a {
								@extend %button;
							}
						}
					}
				}

				&.current-menu-item,
				&:hover {

					> a::after {
						height: 19px;
					}
				}

				&:hover {

					> a::after {
						z-index: 200;
					}

					> .sub-menu {
						// display: flex;
						visibility: visible;

						// transition-duration: 0.3s;

						opacity: 1;
						transform: scale(1);
					}
				}
			}
		}
	}
}

$mob-hh: 60px;
body > header.mobile {
	position: fixed;
	z-index: 3000;
	top: 0;

	display: none;
	justify-content: space-between;
	padding: 0.325rem 0.5rem;
	width: 100%;
	height: $mob-hh;

	background-color: $color-light-green;
	@include material-shadow(1);

	@include with-touch-nav {
		display: flex;
	}

	.logo {
		flex-grow: 1;

		display: block;

		background-position: left center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	// The menu toggle button
	.toggle-off-canvas {
		display: flex;
		align-items: center;

		&::before {
			display: block;
			height: 3rem;
			width: 3rem;

			text-align: center;
			color: $color-dark-green;
			font-size: 3rem;
			line-height: 3rem;
			font-family: FontAwesome;
			content: "\f0c9";

			cursor: pointer;
		}
	}
}

body > .mobile-off-canvas {
	position: fixed;
	left: 0;
	top: $mob-hh;
	z-index: 105;

	transform: translateX(-100%);
	transition: transform 0.5s ease-in-out;

	padding-bottom: 50vh;
	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: calc(100vh - #{$mob-hh});

	background-color: lighten($color-light-green, 8%);
	overflow: auto;

	&.active {
		transform: translateX(0);
	}

	header {
		padding: 0px;
		margin-bottom: 16px;

		border-bottom: 1px solid black;
	}

	nav {
		font-family: "Lora", sans-serif;
		font-size: 1rem;

		li {
			display: block;

			color: $color-dark-green;
			border-top: $color-dark-green 1px solid;

			> a {
				display: block;
				padding: 0.75rem;
			}

			&.current-menu-item > a {
				background-color: $color-light-green;
			}

			.sub-menu {
				padding: 0 0 1rem 1rem;

				li {
					border: 0;

					> a {
						display: block;
						padding: 0.5rem 0;

						&::before {
							content: '\f105';
							font-family: fontawesome;
							color: rgba($color-dark-green, 0.5);
							margin-right: 0.5rem;
						}
					}
				}
			}

			img.menu-image {
				display: none;
			}
		}

		ul.menu > li:last-child {
			background-color: $color-dark-green;

			a {
				color: #FFF;
			}
		}
	}
}