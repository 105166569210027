// Blackout: used for hiding the site.
#blackout {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	top: 60px;
	background-color: black;

	transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 0.7;
		visibility: visible;
		transition-delay: 0s;
	}
}