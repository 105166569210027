body.page-template-page-cannabis main.page {

	.products {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1rem;
		position: relative;

		article {
			flex-grow: 1;
			flex-basis: 15rem;

			display: flex;
			flex-direction: column;
			margin: 1rem;
			padding: 1rem;

			text-align: center;

			background-color: #FFF;
			border: 1px solid $color-lime-green;

			.main-image {
				display: flex;
				align-items: center;
				height: 15rem;
				width: 100%;

				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				text-align: center;
			}

			h5 {
				padding-top: 0.7rem;

				font-size: 1.325rem;
				text-transform: uppercase;
			}

			.content {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				.text {
					flex-grow: 1;
				}

				p {
					margin-top: 0.5rem;
					max-width: 100%;
				}

			}

			.tags {
				margin: 0 0 0.5rem;
				padding: 0.25rem 0.5rem;
				background-color: rgba($color-lime-green, 0.4);

				&::before {
					display: inline-block;

					font-size: 0.7rem;
					color: rgba($color-medium-green, 0.7);
					// text-transform: uppercase;
					content: "Tags: ";
				}

				.tag {
					font-size: 0.875rem;
					color: $color-dark-green;

					&:not(:last-child) {
						&::after {
							content: ", ";
						}
					}
				}
			}

			&.dummy {
				margin-top: 0;
				margin-bottom: 0;
				height: 0;
				visibility: hidden;
			}
		}
	}
}


main.page.single-cannabis {
	font-size: 1.3rem;
	line-height: 1.3em;

	.left {
		display: flex;
		flex-direction: column;
	}

	.left h1 {
		margin: 0 0 1rem;
	}

	p {
		line-height: inherit;
	}

	.tags {
		margin: 0;
		padding: 0.25rem 0.5rem;

		background-color: rgba($color-lime-green, 0.4);

		border: 1px solid $color-lime-green;

		&::before {
			display: inline-block;

			font-size: 0.9rem;
			color: rgba($color-medium-green, 0.7);
			// text-transform: uppercase;
			content: "Tags: ";
		}

		.tag {
			font-size: 1.1rem;
			color: $color-dark-green;

			&:not(:last-child) {
				&::after {
					content: ", ";
				}
			}
		}
	}

	.post-nav {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-grow: 1;

		a {
			font-size: 1.1rem;
		}
	}
}