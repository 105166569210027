body.home.page {
	display: flex;
	flex-direction: column;

	> main {
		flex-grow: 1;
		width: 100%;

		background-image:url('../images/bg-image.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	> footer {
		flex-shrink: 0;
	}
}